import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { Icon, Text } from "@website-builder/ui/shared/elements";
const isB2u = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";

import { Container, CtaWithIcon, CtaWithoutIcon } from "./styles.js";

const Link = forwardRef(
	(
		{
			linkText,
			color,
			iconConfig,
			iconRight,
			arrowCta,
			darkBg,
			disabled,
			children,
			href,
			target,
			...restProps
		},
		ref,
	) => {
		const displayText = children || linkText;
		const [hovered, setHovered] = useState(false);

		if (arrowCta) {
			let linkColor = null;
			let style = null;
			if (isB2u) {
				linkColor = color ? color : "var(--theme-primary)";
				if (darkBg) {
					linkColor = color ? color : "var(--theme-secondary-light)";
				}
				style = {};
			} else if (darkBg) {
				linkColor = "#d8bdff";
			} else {
				linkColor = "#5E0BD1";
			}
			return (
				<Container className="link-element-container">
					<CtaWithIcon
						darkBg={darkBg}
						disabled={disabled}
						href={disabled ? "javascript:void(0)" : href}
						target={disabled ? "_self" : target}
						ref={ref}
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						className="arrow-cta"
						{...restProps}
					>
						{React.isValidElement(displayText) ? (
							displayText
						) : (
							<Text
								className="link-text"
								variant="paragraph_M_bold"
								style={isB2u ? { color: linkColor } : null}
							>
								{displayText}
							</Text>
						)}
						<div className="arrowWrapper">
							<svg
								className="hovered-link"
								width={hovered ? "25" : "16"}
								height="12"
								viewBox={`0 0 ${hovered ? 25 : 16} 12`}
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d={
										hovered
											? "M17.6087 1.10714L18.9988 0C19.5413 0.940476 20.0725 1.76786 20.5924 2.48214C21.1236 3.18452 21.6661 3.81548 22.2199 4.375C22.7737 4.93452 23.3671 5.45833 24 5.94643V6.05357C23.3671 6.52976 22.7737 7.04762 22.2199 7.60714C21.6661 8.16667 21.1236 8.80357 20.5924 9.51786C20.0725 10.2202 19.5413 11.0476 18.9988 12L17.6087 10.875C18.0721 10.125 18.5467 9.45238 19.0327 8.85714C19.5187 8.2619 20.0047 7.75 20.4907 7.32143C20.9767 6.88095 21.4514 6.52976 21.9148 6.26786C22.3782 5.99405 22.8189 5.80357 23.2371 5.69643V6.30357C22.8189 6.19643 22.3782 6.00595 21.9148 5.73214C21.4514 5.45833 20.9767 5.10714 20.4907 4.67857C20.0047 4.23809 19.5187 3.72619 19.0327 3.14286C18.5467 2.54762 18.0721 1.86905 17.6087 1.10714ZM0 5.03571H18.1173C18.8858 5.03571 19.5809 5.06548 20.2025 5.125C20.8354 5.18452 21.4062 5.2619 21.9148 5.35714L23.0506 6L21.9148 6.64286C21.4062 6.72619 20.8354 6.79762 20.2025 6.85714C19.5809 6.91667 18.8858 6.94643 18.1173 6.94643H0V5.03571Z"
											: "M9.60867 1.10714L10.9988 0C11.5413 0.940476 12.0725 1.76786 12.5924 2.48214C13.1236 3.18452 13.6661 3.81548 14.2199 4.375C14.7737 4.93452 15.3671 5.45833 16 5.94643V6.05357C15.3671 6.52976 14.7737 7.04762 14.2199 7.60714C13.6661 8.16667 13.1236 8.80357 12.5924 9.51786C12.0725 10.2202 11.5413 11.0476 10.9988 12L9.60867 10.875C10.0721 10.125 10.5467 9.45238 11.0327 8.85714C11.5187 8.26191 12.0047 7.75 12.4907 7.32143C12.9767 6.88095 13.4514 6.52976 13.9148 6.26786C14.3782 5.99405 14.8189 5.80357 15.2371 5.69643V6.30357C14.8189 6.19643 14.3782 6.00595 13.9148 5.73214C13.4514 5.45833 12.9767 5.10714 12.4907 4.67857C12.0047 4.23809 11.5187 3.72619 11.0327 3.14286C10.5467 2.54762 10.0721 1.86905 9.60867 1.10714ZM0 5.03571H10.1173C10.8858 5.03571 11.5809 5.06548 12.2025 5.125C12.8354 5.18452 13.4062 5.2619 13.9148 5.35714L15.0506 6L13.9148 6.64286C13.4062 6.72619 12.8354 6.79762 12.2025 6.85714C11.5809 6.91667 10.8858 6.94643 10.1173 6.94643H0V5.03571Z"
									}
									fill={linkColor}
								/>
							</svg>
						</div>
					</CtaWithIcon>
				</Container>
			);
		} else if (!iconConfig.hasOwnProperty("iconName")) {
			return (
				<Container className="link-element-container">
					<CtaWithoutIcon
						darkBg={darkBg}
						disabled={disabled}
						href={disabled ? "javascript:void(0)" : href}
						target={disabled ? "_self" : target}
						ref={ref}
						{...restProps}
					>
						{React.isValidElement(displayText) ? (
							displayText
						) : (
							<Text className="link-text" variant="paragraph_M_bold">
								{displayText}
							</Text>
						)}
					</CtaWithoutIcon>
				</Container>
			);
		} else {
			let stroke = iconConfig.stroke;
			if (!iconConfig.stroke) {
				if (darkBg) stroke = "var(--secondary-purple-100)";
				else stroke = "var(--secondary-purple-300)";
			}
			return (
				<Container className="link-element-container">
					<CtaWithIcon
						darkBg={darkBg}
						disabled={disabled}
						iconRight={iconRight}
						href={disabled ? "javascript:void(0)" : href}
						target={disabled ? "_self" : target}
						ref={ref}
						{...restProps}
					>
						{iconConfig.iconName && (
							<Icon
								iconName={iconConfig.iconName}
								stroke={stroke}
								size={iconConfig.size || "1.125rem"}
							/>
						)}
						{React.isValidElement(displayText) ? (
							displayText
						) : (
							<Text variant="paragraph_M_bold">{displayText}</Text>
						)}
					</CtaWithIcon>
				</Container>
			);
		}
	},
);

Link.defaultProps = {
	iconConfig: {},
	iconRight: false,
	arrowCta: false,
	darkBg: false,
	disabled: false,
	target: "_self",
};

Link.propTypes = {
	linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	iconConfig: PropTypes.object,
	iconRight: PropTypes.bool,
	arrowCta: PropTypes.bool,
	darkBg: PropTypes.bool,
	disabled: PropTypes.bool,
	target: PropTypes.string,
};

export default Link;
